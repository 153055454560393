.bg-contain {
    padding-top: 10%; 
    padding-bottom:10%;
}

.contact-bg {
    margin: auto;
    padding: 10px;
    /* top: 25vh; */
    height: auto;
    /* width: fit-content; */
}

.contact-text-h1 {
    text-align: center;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: normal;
    font-size: calc((3 - 1) * 1.2vw + 1rem);
    line-height: 50px;
    

    /* color: #5A6050; */
}