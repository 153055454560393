/* .hero {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url('./banner.jpg');
    background-size: cover;
    background-position: center top;
    height: 100vh;
} */

/* Style the video: 100% width and height to cover the entire window */

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  /* width: 100px;  */
  /* min-height: 100%; */
}
/* Add some content at the bottom of the video/page */
.content {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

.trailsText {
  position: relative;
  width: 100%;
  height: 10vh;
  line-height: 100px;
  /* color: black; */
  font-size: 8em;
  font-weight: 800;
  letter-spacing: -0.03em;
  will-change: transform, opacity;
  overflow: hidden;
}

.container {
 
  display: flex;
  align-items: center;
  /* height: 100vh; */
  justify-content: center;
}
