.mycard {
    display: block;
    cursor: pointer;
    background: #FFFFFF;
    /* width: 300px;
    height: 300px; */
    /* border-radius: 2px; */
    margin: 10px;
    display: inline-block;
    /* align: center; */
    transition: all .15s linear;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.4);
  }
  
  .mycard:hover{
    box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
    /* transform: scale(1.5); */
  }

  @media (hover: none) and (pointer: fine){

  }

 