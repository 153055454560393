.footer {
    background-color: #414141;
    width: 100%;
    /* height: 50vh; */
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
  }